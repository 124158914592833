@import './../../Variables.scss';

.carousel {
  height: 100%;
  width: 100%;
  .autohome-img-cont {
    width: 85%;
    height: calc(100vh - 130px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    svg {
      mix-blend-mode: multiply;
      position: absolute;
      left: 0;
      width: 100%;
    }
  }
  .carousel-indicators {
    background-color: map-get($theme-colors, 'grey');
    height: 5px;
    border-radius: 10px;
    li {
      margin: 0;
      border: 0;
      height: 5px;
      border-radius: 10px;
      width: 16.7%;
      background-color: map-get($theme-colors, 'grey-dark');
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      &.active {
        opacity: 1;
      }
    }
  }
  .carousel-control-prev {
    opacity: 1;
    .carousel-control-prev-icon {
      background-image: url('../../images/icn_prev.svg');
      width: 50px;
      height: 50px;
    }
  }
  .carousel-control-next {
    opacity: 1;
    .carousel-control-next-icon {
      background-image: url('../../images/icn_next.svg');
      width: 50px;
      height: 50px;
    }
  }
  @media only screen and (max-width: 991px) {
    height: auto;
    .autohome-img-cont {
      height: calc(100vh - 220px);
    }
    .carousel-indicators {
      bottom: 60px;
    }
  }
}
