@import './../../Variables.scss';

.navbar {
  position: fixed;
  background-color: transparent;
  color: map-get($theme-colors, 'white');
  overflow: inherit;
  width: 100%;
  padding: 0 0 0 5%;
  z-index: 2;
  .navbar-brand {
    padding: 0;
    margin: 0 2px;
    img {
      width: 295px;
    }
    &::before {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 5%;
      height: 80px;
      background-color: map-get($theme-colors, 'black');
    }
  }
  .navbar-toggler {
    border: 0;
    &:focus {
      outline: 0;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }
  .navbar-collapse {
    text-transform: uppercase;
    background-color: map-get($theme-colors, 'black');
    height: 80px;
    padding: 0 5% 0 1rem;
    .navbar-brand {
      background-color: map-get($theme-colors, 'white');
    }
    ul {
      .nav-item {
        .nav-link {
          color: map-get($theme-colors, 'white');
          font-family: $HelveticaNeueLTBold;
          padding-bottom: 0;
          font-size: 15px;
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    padding: 0;
    background-color: map-get($theme-colors, 'black');
    .navbar-brand {
      background-color: map-get($theme-colors, 'white');
      margin: 0;
      &::before {
        display: none;
      }
    }
    .navbar-collapse {
      text-align: center;
      height: auto;
      padding: 10px 30px 5px;
    }
  }
  @media only screen and (max-width: 530px) {
    .navbar-brand {
      img {
        width: 150px;
      }
    }
  }
}
