@import './../../Variables.scss';

.nav-tabs {
  .nav-item {
    margin-right: 15px;
    .nav-link {
      font-family: $HelveticaNeueMedium;
      color: map-get($theme-colors, 'black');
      border: 0;
      border-bottom: 2px solid transparent;
      padding-right: 2px;
      padding-left: 2px;
      font-size: 14px;
      &.active {
        border-color: map-get($theme-colors, 'black');
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.tab-content {
  padding-top: 20px;
}

.position-svg {
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  left: 0;
}

.position-svg-icon {
  mix-blend-mode: multiply;
  width: 69px;
}
