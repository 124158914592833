@import './../../App.scss';

footer {
  width: 100%;
  background-color: map-get($theme-colors, 'white');
  color: map-get($theme-colors, 'footer-txt');
  padding: 8px 5%;
  font-size: 12px;
  z-index: 2;
  font-family: $HelveticaNeueLTRegular;
  a {
    margin-left: 5px;
    color: map-get($theme-colors, 'footer-txt');
    transition: 0.2s ease-in-out;
    &:last-child {
      margin-right: 5px;
    }
    &:hover {
      color: map-get($theme-colors, 'grey-dark');
      text-decoration: none;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 8px 20px;
  }
}
