@import './Variables.scss';

//GENERAL

body {
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: map-get($theme-colors, 'background');
}

.non-windows-body {
  font-family: $HelveticaNeueRoman;
}

.padding-menu {
  padding-top: 80px;
  @media only screen and (max-width: 767px) {
    padding-top: 0;
  }
}

//CENTRATURA ELEMENTI

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

// MARGIN - PADDING

.m-b-30 {
  margin-bottom: 30px;
}

.m-t-b-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

// BACKGROUND

.u-bg-light {
  background-color: map-get($theme-colors, 'white');
}

// CONT SHADOW - CARD

.card {
  &.login-card {
    margin-top: -80px;
    max-width: 600px;
    @media only screen and (max-width: 575px) {
      margin-top: -40px;
    }
    .card-header {
      background-color: map-get($theme-colors, 'white');
      border: none;
      text-align: center;
      padding-top: 30px;
      .card-title {
        font-size: 20px;
        color: map-get($theme-colors, 'darkborder');
        font-weight: 500;
        span {
          color: map-get($theme-colors, 'black');
          font-weight: 800;
        }
      }
    }
    .card-body {
      padding-right: 40px !important;
      padding-left: 40px !important;
      .btn {
        min-width: 200px;
      }
    }
  }
}

// FORM E LABEL

form {
  .form-group {
    margin-bottom: 24px;
    label {
      color: map-get($theme-colors, 'darkborder');
      font-size: 12px;
    }
    input,
    textarea,
    select {
      border: 0;
      border-bottom: 2px solid map-get($theme-colors, 'primary-contrast');
      border-radius: 0;
      font-weight: 600;
      color: map-get($theme-colors, 'black');
      background-color: transparent;
      &:focus {
        box-shadow: none;
        border-color: map-get($theme-colors, 'primary');
        background-color: transparent;
      }
    }
    .form-text {
      font-size: 10px;
      color: map-get($theme-colors, 'grey');
    }
    .custom-control-label {
      font-style: italic;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: map-get($theme-colors, 'primary-contrast');
      background-color: map-get($theme-colors, 'primary-contrast');
    }
  }
  @media only screen and(max-width: 1564px) {
    &.filter {
      .form-group {
        width: 20%;
        padding-right: 8px;
        label {
          width: 100%;
          justify-content: left;
        }
        select {
          width: 100%;
          justify-content: left;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
        input {
          width: 100%;
          margin-right: 0px !important;
          margin-left: 0px !important;
        }
      }
    }
  }
  @media only screen and(max-width: 837px) {
    &.filter {
      .form-group {
        width: 33.3%;
        &:nth-child(4),
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
  @media only screen and(max-width: 575px) {
    &.filter {
      .form-group {
        margin-bottom: 0;
        label {
          margin-bottom: 0;
        }
        // input{
        //   overflow: hidden;
        // }
      }
    }
  }
}

// BUTTON

button {
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
}

.resp-btn {
  @media only screen and (max-width: 991px) {
    margin-top: 15px;
  }
  @media only screen and (max-width: 575px) {
    margin-top: 0;
  }
}

// REACT-DATE PICKER

.react-datepicker-wrapper {
  input {
    padding: 6px 12px;
    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

.react-datepicker {
  border: 1px solid map-get($theme-colors, 'border');
  font-family: $HelveticaNeueRoman;
  .react-datepicker__triangle {
    border-bottom-color: map-get($theme-colors, 'border');
  }
  .react-datepicker__header {
    border-bottom: 1px solid map-get($theme-colors, 'border');
    .react-datepicker__current-month {
      color: map-get($theme-colors, 'primary-contrast');
    }
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: map-get($theme-colors, 'black');
      }
    }
  }
}

.react-datepicker__day {
  color: map-get($theme-colors, 'black') !important;
  &:hover {
    border-radius: 50%;
  }
}

.react-datepicker__day--today {
  border-radius: 50%;
}

.react-datepicker__day--selected {
  background-color: map-get($theme-colors, 'primary-contrast') !important;
  color: map-get($theme-colors, 'white') !important;
  border-radius: 50%;
}

// MODAL

.modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  &.width-80 {
    max-width: 50%;
    @media only screen and (max-width: 1200px) {
      max-width: 70%;
    }
  }
  @media only screen and (max-width: 991px) {
    display: block;
    justify-content: inherit;
    align-items: inherit;
    margin: 20px auto 0;
    height: auto;
    &.width-80 {
      max-width: 90%;
    }
  }
  .modal-header {
    padding-bottom: 0;
    border-bottom: 0;
    h3 {
      margin-bottom: 0;
    }
    button {
      &.close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        margin: 10px;
        opacity: 1;
        img {
          width: 35px;
          height: 35px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .modal-content {
    border: 0;
    background-color: map-get($theme-colors, 'white');
    -webkit-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.12);
  }
}
