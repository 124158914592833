// VARIABLES

$theme-colors: (
  'black': #111111,
  'white': #ffffff,
  'background': #f6f6f6,
  'background-img': #f0f0f0,
  'grey-dark': #4c4c4c,
  'grey': #cccccc,
  'grey-light': #e5e5e5,
  'primary': #be0e10,
  'footer-txt': #949494,
);

$HelveticaNeueRoman: 'Helvetica Neue Roman';
$HelveticaNeueMedium: 'Helvetica Neue Medium';
$HelveticaNeueLTBold: 'Helvetica Neue LT Std Bold';
$HelveticaNeueLTRegular: 'Helvetica Neue LT Std';

$menu-size: 130px;
