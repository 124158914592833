@import './../../Variables.scss';

.img-open {
  width: 100%;
  height: 70vh;
  background-image: url('./../../images/open-img.jpg');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  @media only screen and (max-width: 767px) {
    height: 40vh;
  }
}

.gif-index {
  width: 70%;
  margin-bottom: 15px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
}
