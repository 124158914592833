@import './../../Variables.scss';

.btn-lang {
  color: map-get($theme-colors, 'white');

  button {
    border: 0;
    background-color: transparent;
    color: map-get($theme-colors, 'white');
    font-size: 13px;
    &.active,
    &:hover,
    &:active,
    &:focus {
      color: map-get($theme-colors, 'primary');
      box-shadow: none;
      outline: 0;
    }
  }
}
